<template>
  <div id="h1_title">
    <h2>{{ title }}</h2>
    <span v-if="subtitle" @click="$emit('go')">{{ subtitle }}</span>
    <svg
      v-if="more"
      width="16"
      height="27"
      viewBox="0 0 16 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 25L14 13.5L2 2"
        stroke="#989898"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      default: "我的订单",
      type: String,
    },
    subtitle: {
      default: "",
      type: String,
    },
    more: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
#h1_title {
  display: flex;
  align-items: center;
  justify-content: center;
  > h2 {
    flex: 1;
    color: #222222;
    font-size: rem(28);
    font-weight: bold;
  }
  > span {
    color: #989898;
    font-size: rem(26);
  }
  > svg {
    margin-left: rem(10);
    width: rem(12);
    height: rem(23);
  }
}
</style>
