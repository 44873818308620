<template>
  <div class="me-capital">
    <div class="li" @click="goRoute('Money')">
      <i>{{ user.available_predeposit }}</i>
      <span>零钱</span>
      <!-- <div class="tips">充值有礼</div> -->
    </div>
    <div class="li" @click="goRoute('Coupon')">
      <i>{{ user.voucher_count }}</i>
      <span>红包卡券</span>
    </div>
    <!-- <div class="li" @click="$router.push({ name: 'points' })">
      <i>{{ user.member_points }}</i>
      <span>积分</span>
    </div> -->
    <!-- @click="$router.push({ name: 'PromotionCenter' })" -->
    <div class="li">
      <i>{{ user.buySumAmount }}</i>
      <span>消费总额</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user: {},
  },
  methods: {
    goRoute(page, q) {
      this.$router.push({
        name: page,
        query: { ...q },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
.me-capital {
  display: flex;
  padding: rem(10) 0 rem(48);
  .li {
    position: relative;
    .tips {
      position: absolute;
      color: #ffffff;
      background: #ff8a48;
      width: rem(88);
      height: rem(28);
      font-size: rem(20);
      top: rem(-24);
      right: 0;
      text-align: center;
      border-radius: rem(18) rem(18) rem(18) 0px;
    }
  }
  > div {
    flex: 1;
  }
  i,
  span {
    display: block;
    text-align: center;
  }
  i {
    color: #222;
    font-size: rem(38);
  }
  span {
    color: #989898;
    font-size: rem(24);
  }
}
</style>
