<template>
  <ul id="me_set">
    <li @click="goRoute('PromotionCenter')">
      <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="22" cy="17" r="15" stroke="#666666" stroke-width="3" />
        <path
          d="M16.4996 31C12 33 8.49952 41.5 16.4996 42.5H27.9999"
          stroke="#666666"
          stroke-width="3"
          stroke-linecap="round"
        />
        <path
          d="M27.5 37.5H37.5"
          stroke="#666666"
          stroke-width="3"
          stroke-linecap="round"
        />
        <path
          d="M32.5 32.5L32.5 42.5"
          stroke="#666666"
          stroke-width="3"
          stroke-linecap="round"
        />
        <path
          d="M28 12V19"
          stroke="#666666"
          stroke-width="3"
          stroke-linecap="round"
        />
        <path
          d="M16 12V19"
          stroke="#666666"
          stroke-width="3"
          stroke-linecap="round"
        />
      </svg>

      <span>推广中心</span>
      <b>高额佣金轻松赚</b>
      <svg
        width="15"
        height="26"
        viewBox="0 0 15 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 2L12.2929 12.2929C12.6834 12.6834 12.6834 13.3166 12.2929 13.7071L2 24"
          stroke="#666666"
          stroke-width="3"
          stroke-linecap="round"
        />
      </svg>
    </li>

    <li @click="goKefu">
      <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 10C2 6.68629 4.68629 4 8 4H36C39.3137 4 42 6.68629 42 10V30C42 33.3137 39.3137 36 36 36H29.7398C29.2624 36 28.8008 36.1708 28.4383 36.4815L22.6508 41.4422C22.2763 41.7632 21.7237 41.7632 21.3492 41.4422L15.5617 36.4815C15.1992 36.1708 14.7376 36 14.2602 36H8C4.68629 36 2 33.3137 2 30V10Z"
          stroke="#666666"
          stroke-width="3"
        />
        <circle cx="14" cy="20" r="1" stroke="#666666" stroke-width="2" />
        <circle cx="22" cy="20" r="1" stroke="#666666" stroke-width="2" />
        <circle cx="30" cy="20" r="1" stroke="#666666" stroke-width="2" />
      </svg>

      <span>专属客服</span>
      <svg
        width="15"
        height="26"
        viewBox="0 0 15 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 2L12.2929 12.2929C12.6834 12.6834 12.6834 13.3166 12.2929 13.7071L2 24"
          stroke="#666666"
          stroke-width="3"
          stroke-linecap="round"
        />
      </svg>
    </li>
    <li @click="goRoute('Address')">
      <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M36.5 17.5C36.5 25.5081 22 38 22 38C22 38 7.5 25.5081 7.5 17.5C7.5 9.49187 13.9919 3 22 3C30.0081 3 36.5 9.49187 36.5 17.5Z"
          stroke="#666666"
          stroke-width="3"
        />
        <circle cx="22.5" cy="17" r="5" stroke="#666666" stroke-width="3" />
        <path
          d="M8 34.5L4.03647 40.4453C3.59343 41.1099 4.06982 42 4.86852 42H39.3333C40.0883 42 40.5709 41.1955 40.2157 40.5294L37 34.5"
          stroke="#666666"
          stroke-width="3"
          stroke-linecap="round"
        />
      </svg>

      <span>收货地址</span>
      <svg
        width="15"
        height="26"
        viewBox="0 0 15 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 2L12.2929 12.2929C12.6834 12.6834 12.6834 13.3166 12.2929 13.7071L2 24"
          stroke="#666666"
          stroke-width="3"
          stroke-linecap="round"
        />
      </svg>
    </li>
    <li @click="goRoute('Suggest')">
      <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="22" cy="22" r="20" stroke="#666666" stroke-width="3" />
        <path d="M20 11H24L23.5 28H20.5L20 11Z" fill="#666666" />
        <circle cx="22" cy="32" r="2" fill="#666666" />
      </svg>

      <span>投诉和反馈</span>
      <svg
        width="15"
        height="26"
        viewBox="0 0 15 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 2L12.2929 12.2929C12.6834 12.6834 12.6834 13.3166 12.2929 13.7071L2 24"
          stroke="#666666"
          stroke-width="3"
          stroke-linecap="round"
        />
      </svg>
    </li>
    <li @click="goRoute('Safe')">
      <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M40.2679 19C41.3397 20.8564 41.3397 23.1436 40.2679 25L33.732 36.3205C32.6603 38.1769 30.6795 39.3205 28.5359 39.3205L15.4641 39.3205C13.3205 39.3205 11.3397 38.1769 10.2679 36.3205L3.73205 25C2.66025 23.1436 2.66025 20.8564 3.73205 19L10.2679 7.67949C11.3397 5.82308 13.3205 4.67949 15.4641 4.67949L28.5359 4.67949C30.6795 4.67949 32.6603 5.82308 33.7321 7.67949L40.2679 19Z"
          stroke="#666666"
          stroke-width="3"
        />
        <circle cx="22" cy="22" r="5" stroke="#666666" stroke-width="3" />
      </svg>

      <span>账号设置</span>
      <svg
        width="15"
        height="26"
        viewBox="0 0 15 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 2L12.2929 12.2929C12.6834 12.6834 12.6834 13.3166 12.2929 13.7071L2 24"
          stroke="#666666"
          stroke-width="3"
          stroke-linecap="round"
        />
      </svg>
    </li>
    <oChat :boo.sync="kefuBoo"></oChat>
  </ul>
</template>

<script>
import oChat from "../../view/chat/popup.vue";
export default {
  components: {
    oChat,
  },
  data() {
    return {
      kefuBoo: false,
    };
  },
  methods: {
    goKefu() {
      if (window.__wxjs_environment === "miniprogram") {
        wx.miniProgram.navigateTo({
          url: "../chat/index",
        });
        return false;
      } else {
        this.kefuBoo = true;
      }
    },
    goRoute(page, q) {
      this.$router.push({
        name: page,
        query: { ...q },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
#me_set {
  width: rem(690);
  background: #fff;
  margin: 0 auto;
  border-radius: rem(16);

  li {
    padding: rem(34) rem(32);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    span {
      flex: 1;
      font-size: rem(28);
      color: #222222;
    }
    b {
      font-size: rem(24);
      color: #989898;
      margin-right: rem(10);
    }
    svg {
      &:first-of-type {
        width: rem(44);
        height: rem(44);
        margin-right: rem(26);
      }
      &:last-of-type {
        width: rem(11);
        height: rem(22);
      }
    }
  }
}
</style>
