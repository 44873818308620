<template>
  <ul class="me_commission">
    <li>
      <div>
        <h1
          @click="
            goHref('https://appg5q9tdm86610.h5.xiaoeknow.com/distribute_center')
          "
        >
          学习推广中心
        </h1>
        <span>赚高额佣金</span>
      </div>
      <svg
        width="96"
        height="91"
        viewBox="0 0 96 91"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="45" cy="45" r="45" fill="#FFEEF5" />
        <path
          d="M61 87C60 88.5 44.5 92 33.5 88.5C30 87.3864 25 85.5 20 82.5C17.3553 78.5515 17.4393 71.4602 17 69L14.5 46.5C29.6667 50.8333 62.9411 58.5346 64.5 51C67.5 36.5 93.5 45.5 79.5 54.5C68.3 61.7 62.8333 71.5 61.5 75.5C61.5 75.5 62 85.5 61 87Z"
          fill="#FECAA3"
        />
        <g filter="url(#filter0_d)">
          <path
            d="M47.2592 6.87559C49.3106 4.20195 53.1609 3.74163 55.7847 5.85633L79.8649 25.2643C82.4307 27.3322 82.8485 31.0823 80.8008 33.6642L42.3432 82.1542C40.2398 84.8064 36.3601 85.1945 33.773 83.0116L10.8777 63.6937C8.42779 61.6266 8.03539 57.9988 9.98662 55.4556L47.2592 6.87559Z"
            fill="white"
          />
        </g>
        <path
          d="M47.2591 12.0826C47.9433 11.21 49.2069 11.0606 50.0757 11.7497L75.9417 32.2641C76.8037 32.9478 76.9517 34.1993 76.2728 35.0652L44.2409 75.9174C43.5567 76.79 42.2931 76.9394 41.4243 76.2503L15.5583 55.7359C14.6963 55.0522 14.5483 53.8007 15.2272 52.9348L47.2591 12.0826Z"
          fill="url(#paint0_linear)"
        />
        <g filter="url(#filter1_i)">
          <rect
            x="7"
            y="32.5597"
            width="10.8822"
            height="22.7951"
            rx="5.44112"
            transform="rotate(-10.9102 7 32.5597)"
            fill="url(#paint1_linear)"
          />
        </g>
        <path
          d="M19.0231 51.3197L13.6961 52.6003C12.9724 50.7674 12.2889 46.9718 15.3445 46.4534C18.4001 45.9349 19.0701 49.4815 19.0231 51.3197Z"
          fill="#FFDCC7"
        />
        <circle cx="61" cy="15.5" r="1" fill="#FFD1DD" />
        <rect
          x="63.3535"
          y="16.3808"
          width="9.5963"
          height="2"
          rx="1"
          transform="rotate(39.4453 63.3535 16.3808)"
          fill="#FFD1DD"
        />
        <circle cx="25.5" cy="70" r="3.5" fill="#FFD1DD" />
        <g filter="url(#filter2_i)">
          <rect
            x="14"
            y="25.7712"
            width="12"
            height="25.1365"
            rx="6"
            transform="rotate(-10.9102 14 25.7712)"
            fill="url(#paint2_linear)"
          />
        </g>
        <path
          d="M27.258 46.4581L21.3838 47.8703C20.5858 45.8491 19.8321 41.6637 23.2015 41.092C26.571 40.5203 27.3098 44.4312 27.258 46.4581Z"
          fill="#FFDCC7"
        />
        <g filter="url(#filter3_i)">
          <rect
            x="21"
            y="16.7712"
            width="12"
            height="25.1365"
            rx="6"
            transform="rotate(-10.9102 21 16.7712)"
            fill="url(#paint3_linear)"
          />
        </g>
        <path
          d="M34.258 37.4581L28.3838 38.8703C27.5858 36.8491 26.8321 32.6637 30.2015 32.092C33.571 31.5203 34.3098 35.4312 34.258 37.4581Z"
          fill="#FFDCC7"
        />
        <g filter="url(#filter4_i)">
          <rect
            x="30"
            y="10.7712"
            width="12"
            height="25.1365"
            rx="6"
            transform="rotate(-10.9102 30 10.7712)"
            fill="url(#paint4_linear)"
          />
        </g>
        <path
          d="M43.258 31.4581L37.3838 32.8703C36.5858 30.8491 35.8321 26.6637 39.2015 26.092C42.571 25.5203 43.3098 29.4312 43.258 31.4581Z"
          fill="#FFDCC7"
        />
        <path
          d="M82.8664 50L79.8664 47.5C79.1997 47.6667 77.5664 48.4 76.3664 50C75.1664 51.6 77.1997 53.3333 78.3664 54L82.8664 50Z"
          fill="#FFDCC7"
        />
        <path
          d="M87.5 17.5L90.385 23.115L96 26L90.385 28.885L87.5 34.5L84.615 28.885L79 26L84.615 23.115L87.5 17.5Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M81.5 57.5L83.7062 61.7938L88 64L83.7062 66.2062L81.5 70.5L79.2938 66.2062L75 64L79.2938 61.7938L81.5 57.5Z"
          fill="url(#paint6_linear)"
        />
        <path
          d="M5 42.5L6.35765 45.1424L9 46.5L6.35765 47.8576L5 50.5L3.64235 47.8576L1 46.5L3.64235 45.1424L5 42.5Z"
          fill="url(#paint7_linear)"
        />
        <path
          d="M38.5 37H55.5L47 41L53 48L36 49.5L44.5 44.5L38.5 37Z"
          fill="white"
          fill-opacity="0.4"
        />
        <defs>
          <filter
            id="filter0_d"
            x="4.74707"
            y="2.52765"
            width="81.3533"
            height="87.8984"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="overlay"
              in2="BackgroundImageFix"
              result="effect1_dropShadow"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_i"
            x="7.93066"
            y="31.4305"
            width="15.139"
            height="22.5818"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="2" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
            />
            <feBlend mode="overlay" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="filter2_i"
            x="15.0264"
            y="24.5261"
            width="16.4886"
            height="24.9013"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="2" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
            />
            <feBlend mode="overlay" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="filter3_i"
            x="22.0264"
            y="15.5261"
            width="16.4886"
            height="24.9013"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="2" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
            />
            <feBlend mode="overlay" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="filter4_i"
            x="31.0264"
            y="9.52606"
            width="16.4886"
            height="24.9013"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="2" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
            />
            <feBlend mode="overlay" in2="shape" result="effect1_innerShadow" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="49.5"
            y1="10.5"
            x2="41.5"
            y2="76.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FC7C77" />
            <stop offset="1" stop-color="#FF4375" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="12.4411"
            y1="32.5597"
            x2="12.4411"
            y2="55.3548"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FCD3AC" />
            <stop offset="1" stop-color="#FFBF97" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="20"
            y1="25.7712"
            x2="20"
            y2="50.9077"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FCD3AC" />
            <stop offset="1" stop-color="#FFBF97" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="27"
            y1="16.7712"
            x2="27"
            y2="41.9077"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FCD3AC" />
            <stop offset="1" stop-color="#FFBF97" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="36"
            y1="10.7712"
            x2="36"
            y2="35.9077"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FCD3AC" />
            <stop offset="1" stop-color="#FFBF97" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="87.5"
            y1="17.5"
            x2="87.5"
            y2="34.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FEEA8C" />
            <stop offset="1" stop-color="#FFD408" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="81.5"
            y1="57.5"
            x2="81.5"
            y2="70.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FEEA8C" />
            <stop offset="1" stop-color="#FFD408" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="5"
            y1="42.5"
            x2="5"
            y2="50.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FEEA8C" />
            <stop offset="1" stop-color="#FFD408" />
          </linearGradient>
        </defs>
      </svg>
    </li>
    <li @click="getPosters">
      <div>
        <h1>商城推广中心</h1>
        <span>赚高额佣金</span>
      </div>
      <svg
        width="91"
        height="90"
        viewBox="0 0 91 90"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="45" cy="45" r="45" fill="#FFF9EE" />
        <path
          d="M23.1945 30.4758C29.3401 38.2151 37.218 36.826 40.3888 35.164L45.6425 32.9583L42.3789 36.313L39.6825 38.6854C34.0008 39.3343 29.8814 39.5755 22.0602 32.4404C15.8032 26.7324 8.7464 25.6268 6.00012 25.7876C9.17089 24.1256 17.0488 22.7365 23.1945 30.4758Z"
          fill="#FBD142"
        />
        <path
          d="M73.5705 28.1482C64.3786 24.5185 58.2506 29.6605 56.3356 32.6852L52.8887 37.2222L54.0377 32.6852L55.1867 29.2824C59.7826 25.8796 63.2296 23.6111 73.5705 25.8796C81.8432 27.6944 88.5074 25.1235 90.8053 23.6111C88.8904 26.6358 82.7624 31.7778 73.5705 28.1482Z"
          fill="#FBD142"
        />
        <path
          d="M14 39.5V29.9035L45.1921 36.9927V49.2805L14 39.5Z"
          fill="url(#paint0_linear)"
        />
        <path
          d="M45.1914 36.9927L77.3288 29.9035V39.0633L45.1914 49.2805V36.9927Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M45.6647 24.2322L14 29.9035L45.1921 36.9927L77.3295 29.9035L45.6647 24.2322Z"
          fill="url(#paint2_linear)"
        />
        <g filter="url(#filter0_i)">
          <path
            d="M44.2461 80L45.1913 49.2805L15.417 39.8283L18.2526 69.6026L44.2461 80Z"
            fill="url(#paint3_linear)"
          />
        </g>
        <g filter="url(#filter1_i)">
          <path
            d="M72.6036 70.5478L76.3844 39.3557L45.1923 49.2805L44.2471 80L72.6036 70.5478Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <mask
          id="mask0"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="17"
          y="30"
          width="29"
          height="50"
        >
          <path
            d="M44.2463 80L45.1915 33.6844L17.3076 30.8488L18.2528 69.6026L44.2463 80Z"
            fill="url(#paint5_linear)"
          />
        </mask>
        <g mask="url(#mask0)">
          <path
            d="M33.8496 76.2191L34.7948 34.6296L24.3975 32.2666L25.8153 72.9109L33.8496 76.2191Z"
            fill="#F8CD3C"
          />
        </g>
        <path
          d="M38.5757 30.3762L24.3975 32.2666L34.7948 34.6296L43.7744 32.7392L44.247 29.4309L38.5757 30.3762Z"
          fill="#FFD750"
        />
        <mask
          id="mask1"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="44"
          y="25"
          width="33"
          height="55"
        >
          <path
            d="M72.6036 70.5478L76.3844 25.6501L45.1923 35.5749L44.2471 80L72.6036 70.5478Z"
            fill="url(#paint6_linear)"
          />
        </mask>
        <g mask="url(#mask1)">
          <path
            d="M56.0615 77.637L57.0067 34.157L68.8219 31.794L65.9863 72.9109L56.0615 77.637Z"
            fill="#E3BB32"
          />
        </g>
        <path
          d="M56.0618 28.9583L68.8222 31.794L57.007 34.157L49.4453 31.3214L56.0618 28.9583Z"
          fill="#F4CE4D"
        />
        <path
          d="M46.3396 11.9444C38.0217 11.9444 38.7778 19.8212 40.1957 23.7596C42.7162 24.2322 47.9465 25.1775 48.7027 25.1775C49.6479 25.1775 56.7369 11.9444 46.3396 11.9444Z"
          fill="#FBD142"
        />
        <path
          d="M47.2843 34.157C40.8568 34.5351 38.9349 31.794 38.7773 30.3761V23.287H45.9158L51.5871 25.1774C52.8474 28.0131 53.7118 33.7789 47.2843 34.157Z"
          fill="#FBD142"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M40.2452 23.287C38.0397 20.4514 30.6492 13.9294 24.5998 18.0883C17.038 23.287 25.1217 29.4309 32.1615 30.3761H39.2507L40.2452 23.287ZM37.9924 25.6486C36.7321 23.2855 33.6444 19.032 31.3758 20.9225C28.8698 23.0109 30.055 23.9919 30.6906 24.5179C30.7742 24.5871 30.8483 24.6484 30.9032 24.7033C31.3758 25.1759 36.5746 26.5938 37.9924 25.6486Z"
          fill="#FBD142"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M51.5381 23.287C52.0107 20.7664 54.8463 15.9143 62.4081 16.6705C71.8602 17.6157 66.2383 28.9583 58.6272 30.3761C56.5792 30.5337 52.3888 30.7542 52.0107 30.3761C51.6326 29.998 51.5381 25.4925 51.5381 23.287ZM58.2796 19.5061C61.0396 18.5609 62.7221 21.9637 59.6975 24.2322C58.2796 25.0199 55.0659 26.5007 53.5535 26.1227C53.2385 25.0199 53.7426 22.1527 58.2796 19.5061Z"
          fill="#FBD142"
        />
        <mask
          id="mask2"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="21"
          y="16"
          width="20"
          height="15"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M40.2452 22.8144C38.0397 19.9788 30.6492 13.9294 24.5998 18.0883C17.038 23.287 25.1217 29.4309 32.1615 30.3761H39.2507L40.2452 22.8144ZM37.9924 25.6486C36.7321 23.2855 33.6444 19.032 31.3758 20.9225C28.8698 23.0109 30.055 23.9919 30.6906 24.5179C30.7742 24.5871 30.8483 24.6484 30.9032 24.7033C31.3758 25.1759 36.5746 26.5938 37.9924 25.6486Z"
            fill="#FBD142"
          />
        </mask>
        <g mask="url(#mask2)">
          <path
            d="M27.9569 24.7049C24.176 22.0583 28.9021 20.7665 31.7377 20.4514L37.8817 25.6501L38.8269 27.5405C36.7789 27.698 31.7377 27.3515 27.9569 24.7049Z"
            fill="#FE7F02"
          />
        </g>
        <path
          d="M50.6431 16.6705C50.6431 19.6952 49.3829 22.6569 48.7527 23.7596C47.9965 24.1377 47.8075 20.924 48.2801 19.0336C49.0363 16.3869 50.1705 16.3554 50.6431 16.6705Z"
          fill="#FE7F02"
        />
        <mask
          id="mask3"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="51"
          y="16"
          width="17"
          height="15"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M51.5381 23.287C52.0107 20.7664 54.8463 15.9143 62.4081 16.6705C71.8602 17.6157 66.2383 28.9583 58.6272 30.3761C56.5792 30.5337 52.3888 30.7542 52.0107 30.3761C51.6326 29.998 51.5381 25.4925 51.5381 23.287ZM58.2796 19.5061C61.0396 18.5609 62.7221 21.9637 59.6975 24.2322C58.2796 25.0199 55.0659 26.5007 53.5535 26.1227C53.2385 25.0199 53.7426 22.1527 58.2796 19.5061Z"
            fill="#FBD142"
          />
        </mask>
        <g mask="url(#mask3)">
          <path
            d="M64.3475 19.0335C62.8352 17.8993 59.9365 18.876 58.6762 19.5061L54.8954 22.3418L53.4775 26.1226V26.5952C61.5119 27.0679 66.238 20.4513 64.3475 19.0335Z"
            fill="#FE7F02"
          />
        </g>
        <path
          d="M50.1697 28.0131C50.4848 28.8008 50.9259 30.5652 50.1697 31.3213C50.0122 30.3761 49.7916 28.3912 50.1697 28.0131Z"
          fill="#FE7F02"
        />
        <defs>
          <filter
            id="filter0_i"
            x="15.417"
            y="39.8283"
            width="29.7743"
            height="44.1717"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend mode="overlay" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="filter1_i"
            x="44.2471"
            y="39.3557"
            width="32.1373"
            height="44.6443"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend mode="overlay" in2="shape" result="effect1_innerShadow" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="29.5961"
            y1="29.9035"
            x2="29.5961"
            y2="49.2805"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FA664F" />
            <stop offset="1" stop-color="#FA654F" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="61.2601"
            y1="29.9035"
            x2="61.2601"
            y2="49.7531"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FA3213" />
            <stop offset="1" stop-color="#FA3F22" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="45.901"
            y1="24.2322"
            x2="45.901"
            y2="36.9927"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FF816E" />
            <stop offset="1" stop-color="#FE6F54" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="30.3041"
            y1="40.3009"
            x2="30.3041"
            y2="80"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#F8543E" />
            <stop offset="1" stop-color="#FC3A23" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="60.3157"
            y1="39.8283"
            x2="60.3157"
            y2="80"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#EF260A" />
            <stop offset="1" stop-color="#E51902" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="30.3043"
            y1="40.3009"
            x2="30.3043"
            y2="80"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#F8543E" />
            <stop offset="1" stop-color="#FC3A23" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="60.3157"
            y1="39.8283"
            x2="60.3157"
            y2="80"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#EF260A" />
            <stop offset="1" stop-color="#E51902" />
          </linearGradient>
        </defs>
      </svg>
    </li>

    <Overlay :show="show" @click="show = false">
      <div class="wrapper" @click.stop>
        <div class="erweima_wrapper">
          <img
            class="erweima_img"
            :src="haibao_img"
            alt=""
            @click="getPosters"
          />
          <p>长按保存图片-点击海报可切换</p>
        </div>
      </div>
    </Overlay>
  </ul>
</template>

<script>
import { post } from "@get/http";
import { Overlay, Toast } from "vant";
export default {
  components: {
    Overlay,
  },
  data() {
    return {
      haibao_img: "", //当前海报
      show: false, //海报是否展示
      hb_get_thisHB: 0, //当前海报序列号
      hb_data: [], //海报配置
    };
  },
  created() {
    this.onLoad();
  },
  methods: {
    goHref(src) {
      window.location.href = src;
    },
    async onLoad() {
      // 自启动
      try {
        //获得所有书生海报的配置
        let { data } = await post("/Posters/index", {
          data: {
            page: 1,
          },
        });
        this.hb_data = data;
      } catch (error) {
        console.error(error);
      }
    },
    async getPosters() {
      // 获取书生海报
      Toast.loading({
        message: "加载中...",
        loadingType: "spinner",
        overlay: true,
        forbidClick: true,
        duration: 0,
      });
      if (this.hb_get_thisHB == this.hb_data.length) {
        this.hb_get_thisHB = 0;
      }
      let { posters_image } = await post("/Member/getPosters", {
        data: {
          posters_config_id: this.hb_data[this.hb_get_thisHB].id,
        },
      });
      Toast.clear();
      this.show = true;
      this.haibao_img = env.SITE_URL + posters_image;
      this.hb_get_thisHB++;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
.me_commission {
  width: rem(690);
  margin: 0 auto rem(24);
  display: flex;
  justify-content: space-between;
  li {
    display: flex;
    box-sizing: border-box;
    padding: rem(30) rem(24);
    background: #fff;
    width: rem(333);
    height: rem(143);
    border-radius: rem(16);
    div {
      h1 {
        color: #222222;
        font-size: rem(28);
        font-weight: bold;
        margin-bottom: rem(10);
      }
      span {
        color: #989898;
        font-size: rem(24);
      }
    }
    svg {
      width: rem(90);
      height: rem(90);
    }
  }
}

.wrapper {
  position: relative;
}
.erweima_wrapper {
  // margin:  1rem auto 0 ;
  top: 1rem;
  left: 0;
  right: 0;
  margin: auto;
  display: block;
  width: 70%;
  background: #fff;
  position: absolute;
  p {
    text-align: center;
    padding: 0.2rem 0;
  }

  .erweima_img {
    display: block;
    width: 100%;
  }
}
</style>
