<template>
  <div id="me">
    <!-- 头部 -->
    <oHeader :user="user"></oHeader>
    <!-- 资金 -->
    <oCapital :user="user"></oCapital>
    <!-- 广告 -->
    <oAds :user="user"></oAds>
    <!-- 订单 -->
    <oOrder :user.sync="user"></oOrder>
    <!-- 佣金 -->
    <!-- <oCommission></oCommission> -->
    <!-- 商城 -->
    <oShop></oShop>
    <!-- 学习 -->
    <oStudy></oStudy>
    <!-- 设置 -->
    <oSet></oSet>
  </div>
</template>

<script>
import oHeader from "./components/header/";
import oCapital from "./components/capital/";
import oAds from "./components/ads/";
import oOrder from "./components/order/";
import oCommission from "./components/commission/";
import oShop from "./components/shop/";
import oStudy from "./components/study/";
import oSet from "./components/set/";
import { post } from "@get/http";
export default {
  components: {
    oAds,
    oCapital,
    oHeader,
    oOrder,
    oCommission,
    oShop,
    oStudy,
    oSet,
  },
  data() {
    return {
      user: {}, //用户信息
    };
  },
  created() {
    this.onLoad();
  },
  methods: {
    async onLoad() {
      // 自启动
      try {
        let { member_info } = await post("/v2.Member/index"); // 获得个人信息
        this.user = member_info;
        this.$store.dispatch("doUserInfo", member_info); //更新用户数据
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
#me {
  background: #f8f8f8;
  margin-bottom: rem(180);
}
</style>
