<template>
  <div id="me_order">
    <oTitle subtitle="全部订单" @go="goRouteQuery('Xiaoet_Order')"></oTitle>
    <ul class="me_order-state">
      <li
        @click="
          goRouteQuery('Xiaoet_Order', { tab: 'pay', state_type: 'state_new' })
        "
      >
        <div class="icon">
          <svg
            width="44"
            height="47"
            viewBox="0 0 44 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="44" height="40" rx="6" fill="#FF4848" />
            <path
              d="M24 20C24 16.6863 26.6863 14 30 14H44V26H30C26.6863 26 24 23.3137 24 20Z"
              fill="#FFB6B6"
            />
            <circle cx="31" cy="20" r="2" fill="white" />
          </svg>
          <i
            v-if="user.order_nopay_count < 100 && user.order_nopay_count > 0"
            >{{ user.order_nopay_count }}</i
          >
          <i v-if="user.order_nopay_count > 100">{{
            user.order_nopay_count
          }}</i>
        </div>

        <span>待付款</span>
      </li>

      <li @click="$router.push({ name: 'preSaleStep2' })">
        <div class="icon">
          <svg
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M35.8593 -1.28665e-07C37.1967 -4.82823e-08 38.4456 0.668405 39.1875 1.7812L42.9923 7.48845C43.6494 8.47406 44 9.6321 44 10.8167L44 38C44 41.3137 41.3137 44 38 44L6 44C2.68629 44 -1.80588e-06 41.3137 -1.66103e-06 38L-4.72811e-07 10.8167C-4.21033e-07 9.6321 0.350628 8.47406 1.0077 7.48845L4.81253 1.7812C5.5544 0.668404 6.80332 -1.87502e-06 8.14074 -1.79464e-06L35.8593 -1.28665e-07Z"
              fill="#FF4848"
            />
            <path
              d="M32.8688 2H11.1312C10.8105 2 10.5093 2.15377 10.3213 2.41349L8.14885 5.41349C7.67006 6.07468 8.14245 7 8.95879 7H35.0412C35.8575 7 36.3299 6.07468 35.8511 5.41349L33.6787 2.41349C33.4907 2.15377 33.1895 2 32.8688 2Z"
              fill="white"
            />
            <path
              d="M30 16C30 20.4183 26.4183 24 22 24C17.5817 24 14 20.4183 14 16"
              stroke="#FFB6B6"
              stroke-width="3"
              stroke-linecap="round"
            />
          </svg>
          <i
            v-if="
              user.order_wait_presale_count < 100 &&
                user.order_wait_presale_count > 0
            "
            >{{ user.order_wait_presale_count }}</i
          >
          <i v-if="user.order_wait_presale_count > 100">{{
            user.order_wait_presale_count
          }}</i>
        </div>

        <span>付尾款</span>
      </li>

      <li
        @click="
          goRouteQuery('Xiaoet_Order', { tab: 'get', state_type: 'state_send' })
        "
      >
        <div class="icon">
          <svg
            width="44"
            height="47"
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="44"
              width="44"
              height="44"
              rx="6"
              transform="rotate(90 44 0)"
              fill="#FF4848"
            />
            <rect y="8" width="44" height="2" fill="white" />
            <path d="M15 0H29V18L22 13L15 18V0Z" fill="#FFB6B6" />
          </svg>
          <i
            v-if="
              user.order_noreceipt_count < 100 && user.order_noreceipt_count > 0
            "
            >{{ user.order_noreceipt_count }}</i
          >
          <i class="doc" v-if="user.order_noreceipt_count > 100">...</i>
        </div>

        <span>待收货</span>
      </li>

      <li
        @click="
          goRouteQuery('Xiaoet_Order', {
            tab: 'review',
            state_type: 'state_success',
          })
        "
      >
        <div class="icon">
          <svg
            width="44"
            height="47"
            viewBox="0 0 44 47"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 6C0 2.68629 2.68629 0 6 0H38C41.3137 0 44 2.68629 44 6V34C44 37.3137 41.3137 40 38 40H33H28.3898C26.6099 40 24.9738 40.9773 24.1299 42.5444C23.2166 44.2407 20.7834 44.2407 19.8701 42.5444C19.0262 40.9773 17.3901 40 15.6102 40H13.75H11H8.25H5.5C2.46243 40 0 37.5376 0 34.5V6Z"
              fill="#FF4848"
            />
            <rect x="10" y="14" width="24" height="3" rx="1.5" fill="#FFB6B6" />
            <rect x="10" y="21" width="13" height="3" rx="1.5" fill="#FFB6B6" />
          </svg>
          <i
            v-if="user.order_noeval_count < 100 && user.order_noeval_count > 0"
            >{{ user.order_noeval_count }}</i
          >
          <i class="doc" v-if="user.order_noeval_count > 100">...</i>
        </div>

        <span>待评价</span>
      </li>
      <li @click="goRouteQuery('OrderService')">
        <div class="icon">
          <svg
            width="40"
            height="47"
            viewBox="0 0 40 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="40"
              width="44"
              height="40"
              rx="6"
              transform="rotate(90 40 0)"
              fill="#FF4848"
            />
            <path
              d="M12 0H28V2C28 3.10457 27.1046 4 26 4H14C12.8954 4 12 3.10457 12 2V0Z"
              fill="#FFB6B6"
            />
            <path
              d="M13.8807 13.864H16.2567L20.5047 21.736L24.7527 13.864H27.1287L22.2327 22.48H25.8087V23.68H21.5607V26.032H25.8087V27.232H21.5607V31H19.4487V27.232H15.2247V26.032H19.4487V23.68H15.2247V22.48H18.7767L13.8807 13.864Z"
              fill="#FFB6B6"
            />
          </svg>

          <i
            v-if="user.order_refund_count < 100 && user.order_refund_count > 0"
            >{{ user.order_refund_count }}</i
          >
          <i class="doc" v-if="user.order_refund_count > 100">...</i>
        </div>

        <span>退换/售后</span>
      </li>
    </ul>
  </div>
</template>

<script>
import oTitle from "../title/h1.vue";
export default {
  components: {
    oTitle,
  },
  methods: {
    goRouteQuery(page, q = {}) {
      this.$router.push({
        name: page,
        query: { ...q },
      });
    },
  },
  props: {
    user: {},
  },
  watch: {
    user() {
      // console.log(this.user);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
#me_order {
  padding: rem(30) rem(23);
  box-sizing: border-box;
  background: #fff;
  width: rem(690);
  height: rem(245);
  border-radius: rem(16);
  margin: 0 auto rem(24);
}
.me_order-state {
  padding: rem(42) 0;
  display: flex;
  align-items: center;
  justify-content: center;
  li {
    flex: 1;
  }
  svg {
    display: block;
    width: rem(44);
    height: rem(47);
    margin: 0 auto;
  }
  span {
    color: #222222;
    font-size: rem(24);
    display: block;
    text-align: center;
    padding-top: rem(12);
  }
}

.icon {
  position: relative;
  // margin: 0 auto;
  // width: rem(68);
  // height: rem(68);
  img {
    display: block;
    width: 100%;
    height: 100%;
  }
  i {
    position: absolute;
    width: rem(30);
    height: rem(30);
    background: #ff4848;
    border: rem(2) solid #ffffff;
    border-radius: 50%;
    top: rem(-15);
    right: rem(18);
    color: #fff;
    font-size: rem(18);
    text-align: center;
  }
  .doc {
    line-height: rem(20);
  }
}
</style>
