<template>
  <div class="me-header flex-container">
    <div class="me-header-userinfo">
      <div class="user">
        <h1>{{ user.member_nickname }}</h1>
        <img :src="getGradeImg(user['19jiang_inviter_level_id'])" />
      </div>

      <p @click="doCopy(user.member_id)">ID码: {{ user.member_id }}</p>
    </div>
    <div class="profile">
      <img
        v-if="showBorder"
        src="https://image.shop.19jiang.cn/home/store/goods/2/alioss_2_2022010415340517944.png"
        class="border"
        @click="$router.push({ name: 'MeInfo' })"
      />
      <img
        class="me-header-img"
        :src="user.member_avatar"
        @click="$router.push({ name: 'MeInfo' })"
      />
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { get, post } from "@/util/get/http";
export default {
  props: {
    user: {},
  },
  data() {
    return {
      showBorder: false,
    };
  },
  async created() {
    let data = await post(
      `/v3.MemberCardPoster/getList`,
      {
        data: {
          pageSize: 1,
        },
      },
      {
        result: true,
        toast: false,
        resultKey: "ok",
      }
    );
    if (data.code == 10000) {
      this.showBorder = true;
    }
  },
  methods: {
    getGradeImg(val) {
      //获得对应分销等级的键值对图标
      if (val == 0) {
        // return require("@image/me/vip_0.png");
        return require("@image/me/grade_1.png");
      } else if (val == 10) {
        return require("@image/me/grade_2.png");
        // return require("@image/me/vip_1.png");
      } else if (val == 11) {
        // return require("@image/me/vip_2.png");
        return require("@image/me/grade_3.png");
      } else if (val == 12) {
        // return require("@image/me/vip_3.png");
        return require("@image/me/alioss_605e96f77c143.png");
      } else if (val == 13) {
        // return require("@image/me/vip_4.png");
        return require("@image/me/grade_4.png");
      } else if (val == 14) {
        return require("@image/me/vip_5.png");
      } else if (val == 15) {
        return require("@image/me/vip_6.png");
      }
    },
    doCopy: function(copy) {
      //复制
      this.$copyText(copy).then(
        function(e) {
          Toast("微信:已复制");
        },
        function(e) {
          Toast("微信:复制失败");
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";

.me-header {
  padding: rem(32) rem(30);
  .me-header-userinfo {
    flex: 1;
    .user {
      display: flex;
      align-items: center;
      margin-top: rem(26);
      h1 {
        font-weight: bold;
        font-size: rem(38);
        color: #222;
      }
      img {
        // width: rem(108);
        height: rem(44);
        margin-left: rem(8);
      }
      .g {
        width: auto !important;
        height: rem(44);
      }
    }

    > p {
      font-size: rem(24);
      color: #666;
      margin-top: rem(10);
    }
  }
  .profile {
    position: relative;
    width: rem(142);
    height: rem(142);
  }
  .border {
    position: absolute;
    width: rem(162);
    height: rem(162);
    top: rem(-18);
    left: rem(-10);
    transform: scale(1.17);
  }

  .me-header-img {
    border-radius: 50%;
    width: rem(142);
    height: rem(142);
  }
}
</style>
